import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "input__container" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "input__label--required"
}
const _hoisted_4 = ["id", "autocomplete", "disabled", "inputmode", "name", "pattern", "placeholder", "readonly", "type", "value", "status"]
const _hoisted_5 = {
  key: 1,
  class: "input__caption"
}
const _hoisted_6 = {
  key: 2,
  class: "input__caption input__caption--error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.labelText)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.name,
          class: "input__label"
        }, [
          _createTextVNode(_toDisplayString(_ctx.labelText) + " ", 1),
          (_ctx.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
            : _createCommentVNode("", true)
        ], 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([{
        'input__icons--before': _ctx.iconBefore,
        'input__icons--after': _ctx.iconAfter,
        'input__icons_container--error': _ctx.errorText,
        'input__icons_container--no-border' : _ctx.noBorder
      }, "input__icons_container"])
    }, [
      (_ctx.iconBefore)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 0,
            class: _normalizeClass(`input__icon_before--${_ctx.iconBeforeClass}`),
            "icon-name": _ctx.iconBefore,
            onClick: _ctx.handleClick
          }, null, 8, ["class", "icon-name", "onClick"]))
        : _createCommentVNode("", true),
      _createElementVNode("input", {
        id: _ctx.name,
        ref: "input",
        autocomplete: _ctx.autocomplete,
        disabled: _ctx.disabled,
        inputmode: _ctx.inputMode,
        name: _ctx.name,
        pattern: _ctx.pattern,
        placeholder: _ctx.placeholder,
        readonly: _ctx.readOnly,
        type: _ctx.localType,
        value: _ctx.modelValue,
        status: _ctx.status,
        class: "input",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args))),
        onInput: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.validateOnInput && _ctx.validateOnInput(...args)), ["prevent","stop"]))
      }, null, 40, _hoisted_4),
      (_ctx.iconAfter)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 1,
            class: _normalizeClass(`input__icon_after--${_ctx.iconAfterClass}`),
            "icon-name": _ctx.iconAfter,
            onClick: _ctx.handleClick
          }, null, 8, ["class", "icon-name", "onClick"]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.captionText)
      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.captionText), 1))
      : _createCommentVNode("", true),
    (_ctx.localError)
      ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.localError), 1))
      : _createCommentVNode("", true)
  ]))
}