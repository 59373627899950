<template>
  <div class="input__container">
    <label
      v-if="labelText"
      :for="name"
      class="input__label"
    >
      {{ labelText }}&nbsp;<span
        v-if="required"
        class="input__label--required"
      >*</span>
    </label>
    <div
      :class="{
        'input__icons--before': iconBefore,
        'input__icons--after': iconAfter,
        'input__icons_container--error': errorText,
        'input__icons_container--no-border' : noBorder
      }"
      class="input__icons_container"
    >
      <Icon
        v-if="iconBefore"
        :class="`input__icon_before--${iconBeforeClass}`"
        :icon-name="iconBefore"
        @click="handleClick"
      />
      <input
        :id="name"
        ref="input"
        :autocomplete="autocomplete"
        :disabled="disabled"
        :inputmode="inputMode"
        :name="name"
        :pattern="pattern"
        :placeholder="placeholder"
        :readonly="readOnly"
        :type="localType"
        :value="modelValue"
        :status="status"
        class="input"
        @click="handleClick"
        @input.prevent.stop="validateOnInput"
      >
      <Icon
        v-if="iconAfter"
        :class="`input__icon_after--${iconAfterClass}`"
        :icon-name="iconAfter"
        @click="handleClick"
      />
    </div>
    <span
      v-if="captionText"
      class="input__caption"
    >{{ captionText }}</span>
    <span
      v-if="localError"
      class="input__caption input__caption--error"
    >{{ localError }}</span>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, onBeforeUnmount, Ref, ref, watchEffect,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { v4 as uuidv4 } from 'uuid';
import Icon from '@/components/Icon.vue';
import useValidateForm from '@/util/useHooks/useValidateForm';

export default defineComponent({
  name: 'LKFInput',
  components: { Icon },
  props: {
    modelValue: { type: String, default: '' },
    name: { type: String, required: true },
    type: { type: String, default: 'text' },
    inputMode: { type: String, default: 'text' },
    placeholder: { type: String, required: true },
    readOnly: { type: Boolean, default: false },
    noBorder: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    skipValidation: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    labelText: { type: String, default: '' },
    captionText: { type: String, default: '' },
    errorText: { type: String, default: '' },
    iconBefore: { type: String, default: '' },
    iconAfter: { type: String, default: '' },
    iconAfterClass: { type: String, default: '' },
    iconBeforeClass: { type: String, default: '' },
    autocomplete: { type: String, default: 'off' },
    pattern: { type: String, default: '' },
    patternError: { type: String, default: null },
    status: { type: Boolean, default: false },
  },
  emits: ['update:modelValue', 'input'],
  setup(props, { emit }) {
    const input: Ref<HTMLInputElement | null> = ref(null);
    const localError = ref(props.errorText);
    watchEffect(() => {
      localError.value = props.errorText;
    });
    const localType = ref('');
    watchEffect(() => {
      if (props.type !== 'email') {
        localType.value = props.type;
        return;
      }
      localType.value = '';
    });

    const { t } = useI18n();

    function handleClick() {
      (input.value as HTMLInputElement).focus();
    }

    const getPatternErrorMessage = (pattern: string, value: string): string => {
      const lastChar = (value[value.length - 1] || '').toUpperCase();
      switch (pattern) {
        case '[0-9a-zA-Z\\-\\/]{0,20}':
          return t('input.error.patternNotAllowedPolicy', { pattern, char: lastChar });
        case '[0-9]+':
          return t('input.error.onlyNumbersAllowed', { pattern, char: lastChar });
        default:
          return t('input.error.patternNotAllowed', { pattern, char: lastChar });
      }
    };

    const validate = (newValue: string, isDelete = false): boolean | null => {
      localError.value = props.errorText;
      if (props.disabled || props.skipValidation) return true;
      if (props.type === 'email') {
        if (!newValue.match(props.pattern)) {
          localError.value = t('input.error.notEmail');
          return false;
        }
      }
      if (props.pattern && !isDelete && newValue && props.type !== 'email') {
        const inputElement = input.value as HTMLInputElement;
        if (inputElement.validity.patternMismatch) {
          localError.value = getPatternErrorMessage(props.pattern, newValue);
          return null;
        }
      }

      if (props.status) {
        localError.value = t('views.unregisteredVehicle.error.nopolicyNumberMatch');
        return false;
      }

      if (props.required && !newValue) {
        localError.value = t('general.fieldIsRequired');
        return false;
      }
      return true;
    };

    const validateOnInput = (e: InputEvent) => {
      const newValue = (e.target as HTMLInputElement)?.value;
      const valid = validate(
        newValue,
        (
          e.inputType && (
            e.inputType.startsWith('deleteContent')
            || e.inputType.startsWith('deleteContentBackward')
          )
        ) || !e.inputType,
      );
      if (valid === null) return;
      emit('update:modelValue', newValue);
      emit('input', e);
    };

    const { addValidator, removeValidator } = useValidateForm();
    const uuid = uuidv4();
    addValidator(uuid, () => !!validate(props.modelValue), () => {
      localError.value = '';
    });
    onBeforeUnmount(() => removeValidator(uuid));
    return {
      input,
      uuid,
      localError,
      localType,
      handleClick,
      validateOnInput,
    };
  },
});
</script>
